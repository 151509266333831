import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  getAllUsers,
  getValidation,
  getAllGroups,
  broadCastMessage,
  scheduleBroadcastMessage,
  updateStatus,
  updateSchedule,
  updateStatusScheduleBroadcast,
} from "../../../actions/userAction";
import {
  Col,
  Form,
  Input,
  Row,
  Modal,
  Button,
  Table,
  Tag,
  Spin,
  message,
  Space,
  Select,
  Card,
  Switch,
  Tooltip,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Navigate } from "react-router-dom";
import PreviewUploadedImage from "./PreviewUploadedImage";
import pdfImg from "../../../assests/img/pdf-img.webp";
import { Auth } from "aws-amplify";

const { TextArea } = Input;
const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const antIconSpinner = (
  <LoadingOutlined
    style={{
      fontSize: "40px",
    }}
    spin
  />
);
class BroadcastLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleValid: false,
      loadingBroadcast: false,
      disableMessage: false,
      message: "",
      modelOpen: false,
      fileName: "",
      recipients: [],
      externalRecipients: [],
      externalName: "",
      externalNumber: "",
      externalCode: null,
      noRecipientsSelectedError: false,
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      inputFile: null,
      image: "",
      users: "",
      recipientUpdate: true,
      update: false,
      messageUpdate: false,
      groupList: [],
      groupActive: false,
      showUserTable: true,
      errors: {},
      search: "",

      toggleView: true,
      countryCode: [
        {
          code: "+7 840",
          name: "Abkhazia",
        },
        {
          code: "+93",
          name: "Afghanistan",
        },
        {
          code: "+355",
          name: "Albania",
        },
        {
          code: "+213",
          name: "Algeria",
        },
        {
          code: "+1 684",
          name: "American Samoa",
        },
        {
          code: "+376",
          name: "Andorra",
        },
        {
          code: "+244",
          name: "Angola",
        },
        {
          code: "+1 264",
          name: "Anguilla",
        },
        {
          code: "+1 268",
          name: "Antigua and Barbuda",
        },
        {
          code: "+54",
          name: "Argentina",
        },
        {
          code: "+374",
          name: "Armenia",
        },
        {
          code: "+297",
          name: "Aruba",
        },
        {
          code: "+247",
          name: "Ascension",
        },
        {
          code: "+61",
          name: "Australia",
        },
        {
          code: "+672",
          name: "Australian External Territories",
        },
        {
          code: "+43",
          name: "Austria",
        },
        {
          code: "+994",
          name: "Azerbaijan",
        },
        {
          code: "+1 242",
          name: "Bahamas",
        },
        {
          code: "+973",
          name: "Bahrain",
        },
        {
          code: "+880",
          name: "Bangladesh",
        },
        {
          code: "+1 246",
          name: "Barbados",
        },
        {
          code: "+1 268",
          name: "Barbuda",
        },
        {
          code: "+375",
          name: "Belarus",
        },
        {
          code: "+32",
          name: "Belgium",
        },
        {
          code: "+501",
          name: "Belize",
        },
        {
          code: "+229",
          name: "Benin",
        },
        {
          code: "+1 441",
          name: "Bermuda",
        },
        {
          code: "+975",
          name: "Bhutan",
        },
        {
          code: "+591",
          name: "Bolivia",
        },
        {
          code: "+387",
          name: "Bosnia and Herzegovina",
        },
        {
          code: "+267",
          name: "Botswana",
        },
        {
          code: "+55",
          name: "Brazil",
        },
        {
          code: "+246",
          name: "British Indian Ocean Territory",
        },
        {
          code: "+1 284",
          name: "British Virgin Islands",
        },
        {
          code: "+673",
          name: "Brunei",
        },
        {
          code: "+359",
          name: "Bulgaria",
        },
        {
          code: "+226",
          name: "Burkina Faso",
        },
        {
          code: "+257",
          name: "Burundi",
        },
        {
          code: "+855",
          name: "Cambodia",
        },
        {
          code: "+237",
          name: "Cameroon",
        },
        {
          code: "+1",
          name: "Canada",
        },
        {
          code: "+238",
          name: "Cape Verde",
        },
        {
          code: "+ 345",
          name: "Cayman Islands",
        },
        {
          code: "+236",
          name: "Central African Republic",
        },
        {
          code: "+235",
          name: "Chad",
        },
        {
          code: "+56",
          name: "Chile",
        },
        {
          code: "+86",
          name: "China",
        },
        {
          code: "+61",
          name: "Christmas Island",
        },
        {
          code: "+61",
          name: "Cocos-Keeling Islands",
        },
        {
          code: "+57",
          name: "Colombia",
        },
        {
          code: "+269",
          name: "Comoros",
        },
        {
          code: "+242",
          name: "Congo",
        },
        {
          code: "+243",
          name: "Congo, Dem. Rep. of (Zaire)",
        },
        {
          code: "+682",
          name: "Cook Islands",
        },
        {
          code: "+506",
          name: "Costa Rica",
        },
        {
          code: "+385",
          name: "Croatia",
        },
        {
          code: "+53",
          name: "Cuba",
        },
        {
          code: "+599",
          name: "Curacao",
        },
        {
          code: "+537",
          name: "Cyprus",
        },
        {
          code: "+420",
          name: "Czech Republic",
        },
        {
          code: "+45",
          name: "Denmark",
        },
        {
          code: "+246",
          name: "Diego Garcia",
        },
        {
          code: "+253",
          name: "Djibouti",
        },
        {
          code: "+1 767",
          name: "Dominica",
        },
        {
          code: "+1 809",
          name: "Dominican Republic",
        },
        {
          code: "+670",
          name: "East Timor",
        },
        {
          code: "+56",
          name: "Easter Island",
        },
        {
          code: "+593",
          name: "Ecuador",
        },
        {
          code: "+20",
          name: "Egypt",
        },
        {
          code: "+503",
          name: "El Salvador",
        },
        {
          code: "+240",
          name: "Equatorial Guinea",
        },
        {
          code: "+291",
          name: "Eritrea",
        },
        {
          code: "+372",
          name: "Estonia",
        },
        {
          code: "+251",
          name: "Ethiopia",
        },
        {
          code: "+500",
          name: "Falkland Islands",
        },
        {
          code: "+298",
          name: "Faroe Islands",
        },
        {
          code: "+679",
          name: "Fiji",
        },
        {
          code: "+358",
          name: "Finland",
        },
        {
          code: "+33",
          name: "France",
        },
        {
          code: "+596",
          name: "French Antilles",
        },
        {
          code: "+594",
          name: "French Guiana",
        },
        {
          code: "+689",
          name: "French Polynesia",
        },
        {
          code: "+241",
          name: "Gabon",
        },
        {
          code: "+220",
          name: "Gambia",
        },
        {
          code: "+995",
          name: "Georgia",
        },
        {
          code: "+49",
          name: "Germany",
        },
        {
          code: "+233",
          name: "Ghana",
        },
        {
          code: "+350",
          name: "Gibraltar",
        },
        {
          code: "+30",
          name: "Greece",
        },
        {
          code: "+299",
          name: "Greenland",
        },
        {
          code: "+1 473",
          name: "Grenada",
        },
        {
          code: "+590",
          name: "Guadeloupe",
        },
        {
          code: "+1 671",
          name: "Guam",
        },
        {
          code: "+502",
          name: "Guatemala",
        },
        {
          code: "+224",
          name: "Guinea",
        },
        {
          code: "+245",
          name: "Guinea-Bissau",
        },
        {
          code: "+595",
          name: "Guyana",
        },
        {
          code: "+509",
          name: "Haiti",
        },
        {
          code: "+504",
          name: "Honduras",
        },
        {
          code: "+852",
          name: "Hong Kong SAR China",
        },
        {
          code: "+36",
          name: "Hungary",
        },
        {
          code: "+354",
          name: "Iceland",
        },
        {
          code: "+91",
          name: "India",
        },
        {
          code: "+62",
          name: "Indonesia",
        },
        {
          code: "+98",
          name: "Iran",
        },
        {
          code: "+964",
          name: "Iraq",
        },
        {
          code: "+353",
          name: "Ireland",
        },
        {
          code: "+972",
          name: "Israel",
        },
        {
          code: "+39",
          name: "Italy",
        },
        {
          code: "+225",
          name: "Ivory Coast",
        },
        {
          code: "+1 876",
          name: "Jamaica",
        },
        {
          code: "+81",
          name: "Japan",
        },
        {
          code: "+962",
          name: "Jordan",
        },
        {
          code: "+7 7",
          name: "Kazakhstan",
        },
        {
          code: "+254",
          name: "Kenya",
        },
        {
          code: "+686",
          name: "Kiribati",
        },
        {
          code: "+965",
          name: "Kuwait",
        },
        {
          code: "+996",
          name: "Kyrgyzstan",
        },
        {
          code: "+856",
          name: "Laos",
        },
        {
          code: "+371",
          name: "Latvia",
        },
        {
          code: "+961",
          name: "Lebanon",
        },
        {
          code: "+266",
          name: "Lesotho",
        },
        {
          code: "+231",
          name: "Liberia",
        },
        {
          code: "+218",
          name: "Libya",
        },
        {
          code: "+423",
          name: "Liechtenstein",
        },
        {
          code: "+370",
          name: "Lithuania",
        },
        {
          code: "+352",
          name: "Luxembourg",
        },
        {
          code: "+853",
          name: "Macau SAR China",
        },
        {
          code: "+389",
          name: "Macedonia",
        },
        {
          code: "+261",
          name: "Madagascar",
        },
        {
          code: "+265",
          name: "Malawi",
        },
        {
          code: "+60",
          name: "Malaysia",
        },
        {
          code: "+960",
          name: "Maldives",
        },
        {
          code: "+223",
          name: "Mali",
        },
        {
          code: "+356",
          name: "Malta",
        },
        {
          code: "+692",
          name: "Marshall Islands",
        },
        {
          code: "+596",
          name: "Martinique",
        },
        {
          code: "+222",
          name: "Mauritania",
        },
        {
          code: "+230",
          name: "Mauritius",
        },
        {
          code: "+262",
          name: "Mayotte",
        },
        {
          code: "+52",
          name: "Mexico",
        },
        {
          code: "+691",
          name: "Micronesia",
        },
        {
          code: "+1 808",
          name: "Midway Island",
        },
        {
          code: "+373",
          name: "Moldova",
        },
        {
          code: "+377",
          name: "Monaco",
        },
        {
          code: "+976",
          name: "Mongolia",
        },
        {
          code: "+382",
          name: "Montenegro",
        },
        {
          code: "+1664",
          name: "Montserrat",
        },
        {
          code: "+212",
          name: "Morocco",
        },
        {
          code: "+95",
          name: "Myanmar",
        },
        {
          code: "+264",
          name: "Namibia",
        },
        {
          code: "+674",
          name: "Nauru",
        },
        {
          code: "+977",
          name: "Nepal",
        },
        {
          code: "+31",
          name: "Netherlands",
        },
        {
          code: "+599",
          name: "Netherlands Antilles",
        },
        {
          code: "+1 869",
          name: "Nevis",
        },
        {
          code: "+687",
          name: "New Caledonia",
        },
        {
          code: "+64",
          name: "New Zealand",
        },
        {
          code: "+505",
          name: "Nicaragua",
        },
        {
          code: "+227",
          name: "Niger",
        },
        {
          code: "+234",
          name: "Nigeria",
        },
        {
          code: "+683",
          name: "Niue",
        },
        {
          code: "+672",
          name: "Norfolk Island",
        },
        {
          code: "+850",
          name: "North Korea",
        },
        {
          code: "+1 670",
          name: "Northern Mariana Islands",
        },
        {
          code: "+47",
          name: "Norway",
        },
        {
          code: "+968",
          name: "Oman",
        },
        {
          code: "+92",
          name: "Pakistan",
        },
        {
          code: "+680",
          name: "Palau",
        },
        {
          code: "+970",
          name: "Palestinian Territory",
        },
        {
          code: "+507",
          name: "Panama",
        },
        {
          code: "+675",
          name: "Papua New Guinea",
        },
        {
          code: "+595",
          name: "Paraguay",
        },
        {
          code: "+51",
          name: "Peru",
        },
        {
          code: "+63",
          name: "Philippines",
        },
        {
          code: "+48",
          name: "Poland",
        },
        {
          code: "+351",
          name: "Portugal",
        },
        {
          code: "+1 787",
          name: "Puerto Rico",
        },
        {
          code: "+974",
          name: "Qatar",
        },
        {
          code: "+262",
          name: "Reunion",
        },
        {
          code: "+40",
          name: "Romania",
        },
        {
          code: "+7",
          name: "Russia",
        },
        {
          code: "+250",
          name: "Rwanda",
        },
        {
          code: "+685",
          name: "Samoa",
        },
        {
          code: "+378",
          name: "San Marino",
        },
        {
          code: "+966",
          name: "Saudi Arabia",
        },
        {
          code: "+221",
          name: "Senegal",
        },
        {
          code: "+381",
          name: "Serbia",
        },
        {
          code: "+248",
          name: "Seychelles",
        },
        {
          code: "+232",
          name: "Sierra Leone",
        },
        {
          code: "+65",
          name: "Singapore",
        },
        {
          code: "+421",
          name: "Slovakia",
        },
        {
          code: "+386",
          name: "Slovenia",
        },
        {
          code: "+677",
          name: "Solomon Islands",
        },
        {
          code: "+27",
          name: "South Africa",
        },
        {
          code: "+500",
          name: "South Georgia and the South Sandwich Islands",
        },
        {
          code: "+82",
          name: "South Korea",
        },
        {
          code: "+34",
          name: "Spain",
        },
        {
          code: "+94",
          name: "Sri Lanka",
        },
        {
          code: "+249",
          name: "Sudan",
        },
        {
          code: "+597",
          name: "Suriname",
        },
        {
          code: "+268",
          name: "Swaziland",
        },
        {
          code: "+46",
          name: "Sweden",
        },
        {
          code: "+41",
          name: "Switzerland",
        },
        {
          code: "+963",
          name: "Syria",
        },
        {
          code: "+886",
          name: "Taiwan",
        },
        {
          code: "+992",
          name: "Tajikistan",
        },
        {
          code: "+255",
          name: "Tanzania",
        },
        {
          code: "+66",
          name: "Thailand",
        },
        {
          code: "+670",
          name: "Timor Leste",
        },
        {
          code: "+228",
          name: "Togo",
        },
        {
          code: "+690",
          name: "Tokelau",
        },
        {
          code: "+676",
          name: "Tonga",
        },
        {
          code: "+1 868",
          name: "Trinidad and Tobago",
        },
        {
          code: "+216",
          name: "Tunisia",
        },
        {
          code: "+90",
          name: "Turkey",
        },
        {
          code: "+993",
          name: "Turkmenistan",
        },
        {
          code: "+1 649",
          name: "Turks and Caicos Islands",
        },
        {
          code: "+688",
          name: "Tuvalu",
        },
        {
          code: "+1 340",
          name: "U.S. Virgin Islands",
        },
        {
          code: "+256",
          name: "Uganda",
        },
        {
          code: "+380",
          name: "Ukraine",
        },
        {
          code: "+971",
          name: "United Arab Emirates",
        },
        {
          code: "+44",
          name: "United Kingdom",
        },
        {
          code: "+1",
          name: "United States",
        },
        {
          code: "+598",
          name: "Uruguay",
        },
        {
          code: "+998",
          name: "Uzbekistan",
        },
        {
          code: "+678",
          name: "Vanuatu",
        },
        {
          code: "+58",
          name: "Venezuela",
        },
        {
          code: "+84",
          name: "Vietnam",
        },
        {
          code: "+1 808",
          name: "Wake Island",
        },
        {
          code: "+681",
          name: "Wallis and Futuna",
        },
        {
          code: "+967",
          name: "Yemen",
        },
        {
          code: "+260",
          name: "Zambia",
        },
        {
          code: "+255",
          name: "Zanzibar",
        },
        {
          code: "+263",
          name: "Zimbabwe",
        },
      ],
      eventId: "",
      file: "",
      updateData: null,
      setFile: null,
      fileType: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectRow = this.onSelectRow.bind(this);
    this.onSelectGroupRow = this.onSelectGroupRow.bind(this);
  }

  onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  //Delete Uploaded File
  removeUploadedFile = () => {
    this.setState({
      setFile: null,
      fileList: [],
      previewVisible: false,
      fileName: "",
    });
  };

  handleToggleView = () => {
    this.setState({ toggleView: !this.state.toggleView });
  };
  //Column Search
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            ?.toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //Searching in Columns
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Resetting of the Searched Columns
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  //Handle Cancel
  handleCancel = async () => {
    this.setState({
      previewVisible: false,
    });
  };

  //View File
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  //Before Upload
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isvideo = file.type === "video/mp4";
    const ispdf = file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 5;
    const isLt16M = file.size / 1024 / 1024 < 16;
    const isLt10 = file.size / 1024 / 1024 < 10;

    if (isJpgOrPng) {
      if (!isLt2M) {
        message.error("Image must smaller than 5MB!");
      }
    } else if (isvideo) {
      if (!isLt16M) {
        message.error("Video must smaller than 16MB!");
      }
    } else if (ispdf) {
      if (!isLt10) {
        message.error("Video must smaller than 16MB!");
      }
    } else {
      message.error("You can only upload JPG/PNG/MP4 file!");
    }

    return isLt2M;
  };
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isvideo = file.type === "video/mp4";
    const ispdf = file.type === "application/pdf";
    const isLt5M = file.size / 1024 / 1024 < 5;
    const isLt16M = file.size / 1024 / 1024 < 16;
    const isLt10 = file.size / 1024 / 1024 < 10;

    if (isJpgOrPng) {
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
        return false;
      }
    } else if (isvideo) {
      if (!isLt16M) {
        message.error("Video must smaller than 16MB!");
        return false;
      }
    } else if (ispdf) {
      if (!isLt10) {
        message.error("PDF must smaller than 10MB!");
        return false;
      }
    } else {
      message.error("You can only upload JPG/PNG/MP4 file!");
      return false;
    }
    return true;
  };

  //Handle File Change
  handleFileChange = async (file) => {
    let resp = await this.beforeUpload(file.target.files[0]);
    if (resp) {
      this.setState({ fileName: file.target.files[0].name });
      file.target.files[0].preview = await getBase64(file.target.files[0]);
      let newFileObj = [];
      newFileObj.push(file.target.files[0]);
      this.setState({
        fileList: newFileObj,
        setFile: URL.createObjectURL(file.target.files[0]),
        fileType: file.target.files[0].type,
      });
    }
  };

  //Handle Change
  handleChange = (value) => {
    this.setState({ recipients: value });
  };
  dateToCron = (date) => {
    let convertedDate = new Date(date);
    const minutes = convertedDate.getUTCMinutes();
    const hours = convertedDate.getUTCHours();
    const days = convertedDate.getUTCDate();
    const months = convertedDate.getUTCMonth() + 1;
    const year = convertedDate.getUTCFullYear();
    return `${minutes} ${hours} ${days} ${months} ? ${year}`;
  };

  //Capitalize th string to upper case
  capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  //On Submit the Form
  onSubmit = async (event) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    let username = "";
    const user = await Auth.currentAuthenticatedUser();
    if (user && user.username) {
      username = user.username.includes("azuread_")
        ? user.username.split("azuread_")[1]
        : user.username;
    }
    var email = user.username;
    email = email.split("_");
    email = email[1].split("@");
    email = email[0].split(".");

    let name = `${this.capitalize(email[0])} ${this.capitalize(email[1])}`;
    let arr = [...this.state.recipients, ...this.state.externalRecipients];
    this.setState(
      {
        recipients: arr,
      },
      async () => {
        if (this.state.recipients.length === 0) {
          this.setState({ noRecipientsSelectedError: true });
        } else {
          this.setState({ loadingBroadcast: true });
          this.setState({ disableMessage: true });
          let requestObj = {};
          if (this.state.fileList.length !== 0) {
            let finalUrl = "";
            let url = this.state.fileList[0].preview.split("base64,");
            finalUrl = finalUrl.concat(url[1]);
            var requestObjForUploadUrl;
            var resp;
            if (this.props.type === "Schedule") {
              requestObjForUploadUrl = {
                eventId: this.props.eventId,
                groupId: this.props.groupId,
                fileName: this.state.fileList[0].name,
                message: this.state.message,
                media: "",
                type: this.props.type,
              };
              resp = await axios.post(
                `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl`,
                requestObjForUploadUrl,
                { headers: { Authorization: "Bearer " + accessToken } }
              );
            } else {
              requestObjForUploadUrl = {
                eventId: this.props.eventId,
                groupId: this.props.groupId,
                fileName: this.state.fileList[0].name,
                message: this.state.message,
                media: "",
                type: "Normal",
              };
              resp = await axios.post(
                `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl`,
                requestObjForUploadUrl,
                { headers: { Authorization: "Bearer " + accessToken } }
              );
            }
            if (resp.data.statusCode === 200) {
              var pathName = resp.data.body.pathName;
              var broadCastId = resp.data.body.broadCastId;

              const uploadResponse = await axios.put(
                resp.data.body.presignedPUTURL,
                this.state.fileList[0]
              );
              if (uploadResponse.status === 200) {
                if (this.props.type === "Schedule" && !this.props.update) {
                  let scheduleDateUtc = this.dateToCron(
                    `${this.props.scheduleDate} ${this.props.scheduleTime}`
                  );
                  requestObj = {
                    fileName: this.state.fileList[0].name,
                    file: finalUrl,
                    eventId: this.props.eventId,
                    groupId: this.props.groupId,
                    broadcastMessage: this.state.message,
                    recipients: this.state.recipients,
                    mimetype: this.state.fileList[0].type,
                    pathName: pathName,
                    broadCastId: broadCastId,
                    scheduleDateTime: scheduleDateUtc,
                    type: this.props.type,
                  };
                  this.props.scheduleBroadcastMessage(requestObj, {
                    headers: { Authorization: "Bearer " + accessToken },
                  }); //Schedule Broadcast Message
                }
                else if (this.props.type === "Schedule" && this.props.update) {
                  if (this.props.update) {
                    let scheduleDateUtc = this.dateToCron(
                      `${this.props.scheduleDate} ${this.props.scheduleTime}`
                    );
                    let updatingData = {
                      scheduleId: this.props.updatedData.scheduleId,
                      scheduledatetime: scheduleDateUtc,
                      scheduleBy: name,
                      broadcastId: this.props.updatedData.broadcastId,
                      broadcastMessage: this.state.message,
                      recipients: this.state.recipients,
                      scheduleDateTime: scheduleDateUtc,
                    };
                    this.props.updateSchedule(updatingData, {
                      headers: { Authorization: "Bearer " + accessToken },
                    }); //update Schedule
                  }
                }
                else {
                  requestObj = {
                    file: finalUrl,
                    eventId: this.props.eventId,
                    broadcastMessage: this.state.message,
                    recipients: this.state.recipients,
                    mimetype: this.state.fileList[0].type,
                    pathName: pathName,
                    broadCastId: broadCastId,
                    type: "Normal",
                  };
                  this.props.broadCastMessage(
                    requestObj,
                    this.props.history,
                    accessToken
                  ); //Broadcasting Message
                }
              }
            }
          } else {
            if (this.props.type === "Schedule" && !this.props.update) {
              let scheduleDateUtc = this.dateToCron(
                `${this.props.scheduleDate} ${this.props.scheduleTime}`
              );
              requestObj = {
                eventId: this.props.eventId,
                groupId: this.props.groupId,
                broadcastMessage: this.state.message,
                recipients: this.state.recipients,
                pathName: null,
                broadCastId: broadCastId,
                scheduleDateTime: scheduleDateUtc,
                type: this.props.type,
              };
              this.props.scheduleBroadcastMessage(requestObj, {
                headers: { Authorization: "Bearer " + accessToken },
              }); //Schedule BroadCast Message
            } else if (this.props.type === "Schedule" && this.props.update) {
              if (this.props.update) {
                let scheduleDateUtc = this.dateToCron(
                  `${this.props.scheduleDate} ${this.props.scheduleTime}`
                );
                let updatingData = {
                  scheduleId: this.props.updatedData.scheduleId,
                  scheduledatetime: scheduleDateUtc,
                  broadcastMessage: this.state.message,
                  recipients: this.state.recipients,
                  scheduleDateTime: scheduleDateUtc,
                };
                this.props.updateSchedule(updatingData, {
                  headers: { Authorization: "Bearer " + accessToken },
                }); //Update Scheduled Broadcast
              }
            } else {
              requestObj = {
                eventId: this.props.eventId,
                groupId: this.props.groupId,
                broadcastMessage: this.state.message,
                recipients: this.state.recipients,
                pathName: null,
                broadCastId: broadCastId,
                type: "Normal",
              };
              this.props.broadCastMessage(
                requestObj,
                this.props.history,
                accessToken
              ); //Broadcast Message
            }
          }
        }
      }
    );
  };
  //Remove Recipients
  removeRecipients = (recipient) => {
    const filteredPeople = this.state.recipients.filter(
      (item) => item.empId !== recipient.empId
    );
    this.setState({ recipients: filteredPeople });
  };

  //On Submit Form
  onFinish = (values) => {
    let recipient = {
      id: `${this.state.externalRecipients.length + 100}`,
      cellPhone: `(${values.countryCode}) ${values.number}`,
      name: `${values.name}`,
    };
    this.addExternalRecipient(recipient);
    this.setState({ modelOpen: false });
    this.setState({ externalName: "", externalNumber: "", externalCode: null });
  };

  componentDidUpdate(previousProps, previousState) {
    if (previousProps !== this.props) {
      if (this.state.recipientUpdate && this.props.update) {
        this.setState({
          users: this.props.users,
          message: this.props?.updatedData.message,
          recipients: this.props?.updatedData.selectedRecipients,
          recipientUpdate: false,
          scheduleValid: true,
          updateData: this.props?.updatedData,
        });
      }
      if (this.props.type === "Schedule") {
        if (this.props.broadcastType === "event") {
          this.setState({ recipients: [] });
          this.setState({ groupActive: false });
          if (
            this.props.eventId !== null &&
            this.props.scheduleDate !== null &&
            this.props.scheduleTime !== null
          ) {
            this.setState({
              messageUpdate: true,
              scheduleValid: true,
              updateData: this.p,
            });
          } else if (this.props.eventId === null) {
            this.setState({ recipients: [] });
          } else {
            this.setState({ scheduleValid: false });
          }
        } else if (this.props.broadcastType === "group") {
          this.setState({ recipients: [] });
          if (this.props.groupId !== null) {
            let groupDemo = this.props.groups.find(
              (group) => group._id === this.props.groupId
            )?.members;

            this.setState({
              groupList: groupDemo,
              recipients: groupDemo,
              groupActive: true,
            });
            if (
              this.props.groupId !== null &&
              this.props.scheduleDate !== null &&
              this.props.scheduleTime !== null
            ) {
              this.setState({
                scheduleValid: true,
              });
            } else {
              this.setState({ scheduleValid: false });
            }
          } else if (this.props.groupId === null) {
            this.setState({ recipients: [] });
          }
        } else {
          this.setState({ scheduleValid: false });
        }
      } else {
        this.setState({ scheduleValid: true });
      }
    }
  }

  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    let object = {
      businessUnitName: null,
      businessGroupName: null,
      locationCode: 102,
    };

    await this.props.getAllUsers(object, accessToken); //Get all Users
    await this.props.getAllGroups(accessToken); //Get all Groups
    this.setState({ users: this.props.users });
    this.setState({ loadingBroadcast: false });
    this.setState({ disableMessage: false });
  }

  //Remove external recipients
  removeExternalRecipients = (value) => {
    const filteredRecipients = this.state.externalRecipients.filter(
      (item) => item.id !== value.id
    );
    this.setState({ externalRecipients: filteredRecipients });
  };

  //On Change Broadcast Message
  onChange = (event) => {
    this.setState({
      messageUpdate: false,
      [event.target.name]: event.target.value,
    });
  };

  //On Select Row
  onSelectRow(arr) {
    this.setState({ noRecipientsSelectedError: false });
    this.setState({ recipients: arr });
  }

  //Adding external recipients
  addExternalRecipient(rec) {
    let array = this.state.externalRecipients;
    array.push(rec);
  }

  //On Select  Group
  onSelectGroupRow(arr) {
    this.setState({ noRecipientsSelectedError: false });
    this.setState({ recipients: arr });
  }

  //On Switch Change
  onSwitchChange = (checked) => {
    this.setState({ showUserTable: checked });
  };

  render() {
    const userCol = [
      {
        title: "Name",
        dataIndex: "name",
        align: "left",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Mobile",
        dataIndex: "cellPhone",
        align: "left",
        ...this.getColumnSearchProps("cellPhone"),
      },
      {
        title: "Email",
        dataIndex: "email",
        align: "left",
        ...this.getColumnSearchProps("email"),
      },
      {
        title: "Employee Id",
        dataIndex: "empId",
        align: "left",
        ...this.getColumnSearchProps("empId"),
      },
      {
        title: "Job Title",
        dataIndex: "jobTitle",
        align: "left",
      },
      {
        title: "BU",
        dataIndex: "businessUnit",
        align: "left",
        ...this.getColumnSearchProps("businessUnit"),
      },
    ];
    const groupMemebers = [
      {
        title: "Name",
        dataIndex: "name",
        align: "left",
      },
      {
        title: "Mobile",
        dataIndex: "cellPhone",
        align: "left",
      },
      {
        title: "Email",
        dataIndex: "email",
        align: "left",
      },
      {
        title: "Employee Id",
        dataIndex: "empId",
        align: "left",
      },
      {
        title: "Job Title",
        dataIndex: "jobTitle",
        align: "left",
      },
      {
        title: "BU",
        dataIndex: "businessUnit",
        align: "left",
      },
    ];

    const ChangeStatusToNull = () => {
      this.props.updateStatus();
      return <></>;
    };

    const ChangeStatusToNull_SchedulaBroadcast = () => {
      this.props.updateStatusScheduleBroadcast();
      return <></>;
    };

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const rowSelection = {
      defaultSelectedRowKeys: this.props.update
        ? this.props.updatedData.selectedRecipients.map((item) => item.empId)
        : this.state.recipients.map((item) => item.empId),
      onChange: (defaultSelectedRowKeys, selectedRows) => {
        this.onSelectRow(selectedRows);
      },
    };
    const prefixSelector = (
      <Form.Item name="countryCode" noStyle>
        <Select
          placeholder="code"
          showSearch
          style={{
            width: 90,
          }}
          onChange={(select) => {
            this.setState({ externalCode: select });
          }}
        >
          {this.state.countryCode.map((code) => {
            return <Option value={code.code}>{code.code}</Option>;
          })}
        </Select>
      </Form.Item>
    );
    const { users } = this.props;

    const { previewVisible, previewImage, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload Documents</div>
      </div>
    );

    return (
      <div className="mt-4">
        <div className="card">
          {this.props.sendBroadcastMsg === 200 ? (
            <div>
              <Navigate to="/events" replace={true} state={this.state.id} />
              <ChangeStatusToNull />
            </div>
          ) : (
            ""
          )}
          {this.props.scheduleBroadcastMessageResponse === 200 ? (
            <div>
              <Navigate
                to="/scheduledBroadcast"
                replace={true}
                state={this.state.id}
              />
              <ChangeStatusToNull_SchedulaBroadcast />
            </div>
          ) : (
            ""
          )}
          <div className="card-body p-5">
            <Form
              ref={this.formRef}
              name="add-users"
              fields={[
                {
                  name: ["message"],
                  value: this.state.messageUpdate
                    ? this.state.message
                    : this.state.message,
                },
              ]}
              className="add-users"
              onFinish={this.onSubmit}
            >
              <Modal
                title="Add Recipient"
                style={{
                  top: 20,
                }}
                width={600}
                open={this.state.modelOpen}
                onCancel={() => this.setState({ modelOpen: false })}
                footer={null}
              >
                <>
                  <Form
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    fields={[
                      {
                        name: ["name"],
                        value: this.state.externalName,
                      },
                      {
                        name: ["number"],
                        value: this.state.externalNumber,
                      },
                      {
                        name: ["countryCode"],
                        value: this.state.externalCode,
                      },
                    ]}
                    onFinish={this.onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Name of Recipient"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Recipient name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          this.setState({ externalName: e.target.value });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="number"
                      label="Number of Recipient"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/\d+/g),
                          message: "Please enter number!",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => {
                          this.setState({ externalNumber: e.target.value });
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      wrapperCol={{
                        offset: 8,
                        span: 16,
                      }}
                    >
                      <Button
                        type="primary ok-modal"
                        htmlType="submit"
                        style={{ marginLeft: "50px" }}
                      >
                        Add Recipient
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              </Modal>
              <div></div>

              <Row>
                <Col span={12}>
                  <Tooltip title="Show Recipients' List">
                    <Switch
                      defaultChecked
                      onChange={() => {
                        this.handleToggleView();
                      }}
                    ></Switch>
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    className="float-end ok-modal"
                    style={{ marginTop: "10px" }}
                    onClick={() => {
                      this.setState({
                        modelOpen: true,
                      });
                    }}
                  >
                    Add External Recipient
                  </Button>
                </Col>
              </Row>

              {this.state.recipients.length ||
              this.state.externalRecipients.length ? (
                <div>
                  <Card style={{ margin: "0px 0 0 0px" }}>
                    <Row>
                      {this.state.recipients.length > 0 && (
                        <Col span={18}>
                          <div
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <h4 style={{ color: "red" }}>*</h4>
                            <h4 style={{ fontWeight: "bold" }}>
                              &nbsp; Selected Recipients
                            </h4>
                          </div>
                          <div style={{ height: "90px", overflowX: "scroll" }}>
                            {this.state.recipients.map((recipient) => (
                              <Tag
                                color="blue"
                                style={{
                                  marginRight: "4px",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                  padding: "4px",
                                }}
                              >
                                {recipient.name}
                              </Tag>
                            ))}
                          </div>
                        </Col>
                      )}
                      <Col span={1}></Col>
                      {this.state.externalRecipients.length > 0 && (
                        <Col span={5}>
                          <div
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <h4 style={{ color: "red" }}>*</h4>
                            <h4 style={{ fontWeight: "bold" }}>
                              &nbsp; External Recipients
                            </h4>
                          </div>
                          <div style={{ height: "90px", overflowX: "scroll" }}>
                            {this.state.externalRecipients?.map((recipient) => (
                              <Tag
                                color="blue"
                                style={{
                                  marginRight: "4px",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  fontSize: "14px",
                                  padding: "4px",
                                }}
                              >
                                {recipient.name}
                                <Button
                                  onClick={() =>
                                    this.removeExternalRecipients(recipient)
                                  }
                                  icon={<CloseOutlined />}
                                  size="small"
                                  style={{
                                    border: "none",
                                    backgroundColor: "inherit",
                                  }}
                                ></Button>
                              </Tag>
                            ))}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </div>
              ) : (
                <Col span={12}>
                  <div
                    className="div-resp"
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    <h4 style={{ color: "red", fontSize: "20px" }}>*</h4>
                    <h4 className="select-recp">
                      &nbsp; Please Select Recipients
                    </h4>
                  </div>
                </Col>
              )}
              <hr></hr>
              {!this.state.groupActive ? (
                <Col>
                  {this.state.users.length && this.state.toggleView ? (
                    <Table
                      rowSelection={{
                        ...rowSelection,
                      }}
                      loading={!this.state.users.length}
                      columns={userCol}
                      dataSource={users}
                      rowKey="empId"
                      align="left"
                      pagination={{
                        pageSize: 10,
                      }}
                      scroll={{
                        y: 150,
                      }}
                    />
                  ) : !this.state.toggleView ? (
                    <></>
                  ) : (
                    <div className="p-5 text-center">
                      <Spin indicator={antIcon} />
                    </div>
                  )}
                </Col>
              ) : (
                <>
                  <h4 style={{ color: "red" }}>*</h4>
                  <h4 style={{ fontWeight: "bold" }}>&nbsp; Group Details</h4>
                  <Table
                    columns={groupMemebers}
                    dataSource={this.state.groupList}
                    rowKey="_id"
                    align="left"
                  />
                </>
              )}
              <div>
                <div className=" p-4">
                  <div>
                    <div
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        display: "flex",
                        margin: "0 0 10px 0",
                        justifyContent: "center",
                      }}
                    >
                      <h4 className="broadcast-msg">Broadcast Message</h4>
                    </div>
                    <>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                          <Form.Item name="upload" valuePropName="fileList">
                            {this.state.setFile ? (
                              <Row gutter={[8, 8]}>
                                {(this.state.fileType === "image/png" ||
                                  this.state.fileType === "image/jpeg") && (
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="container-fluid">
                                      <img
                                        alt="Not Available"
                                        width={125}
                                        height={125}
                                        src={this.state.setFile}
                                      />
                                    </div>
                                  </Col>
                                )}
                                {this.state.fileType === "video/mp4" && (
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="container-fluid">
                                      <video width="125" height="125" controls>
                                        <source
                                          src={this.state.setFile}
                                          type="video/mp4"
                                        />
                                      </video>
                                    </div>
                                  </Col>
                                )}
                                {this.state.fileType === "application/pdf" && (
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="container-fluid">
                                      <img
                                        alt="Not Available"
                                        width={125}
                                        height={125}
                                        src={pdfImg}
                                      />
                                    </div>
                                  </Col>
                                )}
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Row className="ms-2">
                                    <div style={{ margin: "0 10px 0 15px" }}>
                                      {this.state.fileType !== "video/mp4" && (
                                        <PreviewUploadedImage
                                          file={this.state.setFile}
                                          fileType={this.state.fileType}
                                        />
                                      )}
                                    </div>
                                    <div style={{ margin: "0 auto 0 0" }}>
                                      <button
                                        onClick={this.removeUploadedFile}
                                        style={{ width: "50px" }}
                                        type="button"
                                        class="btn btn-outline-danger btn-sm"
                                      >
                                        <DeleteOutlined />
                                      </button>
                                    </div>
                                  </Row>
                                </Col>
                                <div className="ms-2 mt-2">
                                  <label>
                                    <h4>{this.state.fileName}</h4>
                                  </label>
                                </div>
                              </Row>
                            ) : (
                              <div>
                                <label
                                  for="inputFile"
                                  style={{
                                    fontSize: "25px",
                                  }}
                                >
                                  <div class="mb-3 drag-area">
                                    <h6>Upload</h6> <PlusOutlined />
                                    <input
                                      class="form-control"
                                      type="file"
                                      id="inputFile"
                                      name="inputFile"
                                      value={this.state.inputFile}
                                      onChange={this.handleFileChange}
                                      hidden
                                      accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4"
                                    />
                                  </div>
                                  <div>
                                    <label>
                                      <h4>{this.state.fileName}</h4>
                                    </label>
                                  </div>{" "}
                                </label>
                              </div>
                            )}

                            <Modal
                              visible={previewVisible}
                              title={previewTitle}
                              footer={null}
                              onCancel={this.handleCancel}
                              destroyOnClose="true"
                            >
                              <img
                                alt="example"
                                style={{ width: "100%" }}
                                src={previewImage}
                              />
                            </Modal>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                          <Form.Item name="message">
                            <TextArea
                              disabled={this.state.disableMessage}
                              placeholder="Type Broadcast message"
                              name="message"
                              value={this.state.message}
                              onChange={this.onChange}
                              rows={6}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={10}></Col>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={6}
                          style={{ margin: " 0px 0 -30px 0" }}
                        >
                          <Form.Item>
                            {this.state.loadingBroadcast && (
                              <div
                                className=""
                                style={{ margin: "20px 128px 32px 50%" }}
                              >
                                <Spin indicator={antIconSpinner} />
                              </div>
                            )}
                            {this.props.update ||
                              (!this.state.loadingBroadcast && (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="ok-modal-broadcast mx-3"
                                  disabled={
                                    (this.state.recipients.length > 0 ||
                                      this.state.externalRecipients.length >
                                        0) &&
                                    this.state.scheduleValid &&
                                    (this.state.message !== "" ||
                                      this.state.fileList.length)
                                      ? false
                                      : true
                                  }
                                  block
                                >
                                  {this.props.type === "Schedule"
                                    ? "Schedule Broadcast"
                                    : "Send Message"}
                                </Button>
                              ))}
                            {!this.props.update ||
                              (!this.state.loadingBroadcast && (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="ok-modal"
                                  disabled={
                                    this.state.recipients.length &&
                                    this.state.scheduleValid &&
                                    this.state.message.length
                                      ? false
                                      : true
                                  }
                                  block
                                >
                                  Update Schedule
                                </Button>
                              ))}
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8}></Col>
                      </Row>
                    </>
                  </div>
                </div>
              </div>
              <hr></hr>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  users: state.users.users,
  groups: state.users.groups,
  sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
  scheduleBroadcastMessageResponse:
    state.users.scheduleBroadcastMessageResponse,
  valid: state.users.getValidation,
});
export default connect(mapStateToProps, {
  getAllUsers,
  getValidation,
  getAllGroups,
  scheduleBroadcastMessage,
  updateSchedule,
  broadCastMessage,
  updateStatus,
  updateStatusScheduleBroadcast,
})(BroadcastLists);
