import React, { useEffect, useRef } from "react";
import moment from "moment";
 
const ChatBubble = ({ message }) => {
  return (
    <div
      className={`chat-bubble ${
        message.message.IsSender ? "sender" : "receiver"
      }`}
    >
      <div className="message-text">{message.message.message}</div>
      <div className="message-timestamp">
        {moment(message.message.timeStamp).format("DD-MM-YY LT")}
      </div>
    </div>
  );
};
 
const ChatBody = ({ messages }) => {
  const chatBodyRef = useRef(null);
 
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, []); // This ensures scrolling happens only on the initial render
 
  return (
    <div
      className="chat-body"
      ref={chatBodyRef}
      style={{ overflowY: "auto", height: "500px" }} // Ensure the container is scrollable
    >
      {messages.map((message, index) => (
        <ChatBubble key={index} message={{ message }} />
      ))}
    </div>
  );
};
 
export default ChatBody;