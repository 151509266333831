import {
  DeleteOutlined,
  DeleteTwoTone,
  EditTwoTone,
  EyeTwoTone,
  MinusCircleTwoTone,
  PlusOutlined,
  QuestionCircleOutlined,
  SaveTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  broadCastMessage,
  deleteUserFromGroup,
  getAllGroups,
} from "../../../actions/userAction";
import pdfImg from "../../../assests/img/pdf-img.webp";
import BreadCrumbs from "../../BreadCrumbs";
import PreviewUploadedImage from "../broadcast/PreviewUploadedImage";
import AddGroup from "./AddGroup";
import AddMembers from "./AddMembers";
const { Option } = Select;
const { Text } = Typography;

const { TextArea } = Input;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBroadcast: false,
      fileList: [],
      inputFile: null,
      previewVisible: false,
      visible: false,
      previewImage: "",
      previewTitle: "",
      message: "",
      members: [],
      groupId: "",
      navigateToBroadcast: false,
      groupObject: "",
      setFile: null,
      fileType: "",
      modalFeedback: false,
      index: "",
      indexNumber: {},
      questionList: [],
      question: "",
      eventList: [],
      eventName: "",
      loading: true,
      feedbackMessage: "",
      feedbackmembers: [],
      feedbackValue: "",
      groupName: "",
      editgroupvisible: false,
      newgroupname: "",
      feedBackSwitch: false,
      sendBtnLoading: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onQueChange = this.onQueChange.bind(this);
    this.feedbackSubmit = this.feedbackSubmit.bind(this);
  }
  cancelCallOnDelete = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.getEventList(accessToken);
  };

  componentDidMount = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let response = await axios.get(
      "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event",
      { headers: { Authorization: "Bearer " + accessToken } }
    );

    this.setState({ loading: false });
    this.setState({ eventList: response.data });
  };
  //Change in Type Broadcast message
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //On Submit Form
  onSubmit = async (event) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    if (this.state.members.length === 0) {
    } else {
      this.setState({ loadingBroadcast: true });
      let requestObj = {};
      if (this.state.fileList.length !== 0) {
        let finalUrl = "";
        let url = this.state.fileList[0].preview.split("base64,");
        finalUrl = finalUrl.concat(url[1]);
        let requestObjForUploadUrl = {
          groupId: this.state.groupId,
          fileName: this.state.fileList[0].name,
          message: this.state.message,
          media: "",
        };
        let resp = await axios.post(
          `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl`,
          requestObjForUploadUrl,
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        if (resp.data.statusCode === 200) {
          var pathName = resp.data.body.pathName;
          var broadCastId = resp.data.body.broadCastId;

          const uploadResponse = await axios.put(
            resp.data.body.presignedPUTURL,
            this.state.fileList[0]
          );
          if (uploadResponse.status === 200) {
            requestObj = {
              file: finalUrl,
              groupId: this.state.groupId,
              broadcastMessage: this.state.message,
              recipients: this.state.members,
              mimetype: this.state.fileList[0].type,
              pathName: pathName,
              broadCastId: broadCastId,
              type: "Normal",
            };
          }
        }
      } else {
        requestObj = {
          groupId: this.state.groupId,
          broadcastMessage: this.state.message,
          recipients: this.state.members,
          pathName: null,
          broadCastId: broadCastId,
          type: "Normal",
        };
      }
      this.props.broadCastMessage(requestObj, this.props.history, accessToken);
      setTimeout(() => {
        this.handleModalCancel();
        this.setState({ loadingBroadcast: false });
      }, 2000);
    }
  };

  //Before Upload
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isvideo = file.type === "video/mp4";
    const ispdf = file.type === "application/pdf";
    const isLt5M = file.size / 1024 / 1024 < 5;
    const isLt16M = file.size / 1024 / 1024 < 16;
    const isLt10 = file.size / 1024 / 1024 < 10;

    if (isJpgOrPng) {
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
        return false;
      }
    } else if (isvideo) {
      if (!isLt16M) {
        message.error("Video must smaller than 16MB!");
        return false;
      }
    } else if (ispdf) {
      if (!isLt10) {
        message.error("PDF must smaller than 10MB!");
        return false;
      }
    } else {
      message.error("You can only upload JPG/PNG/MP4 file!");
      return false;
    }
    return true;
  };

  //On File Change
  handleFileChange = async (file) => {
    let resp = await this.beforeUpload(file.target.files[0]);
    if (resp) {
      this.setState({ fileName: file.target.files[0].name });
      file.target.files[0].preview = await getBase64(file.target.files[0]);
      let newFileObj = [];
      newFileObj.push(file.target.files[0]);
      this.setState({
        fileList: newFileObj,
        setFile: URL.createObjectURL(file.target.files[0]),
        fileType: file.target.files[0].type,
      });
    }
  };

  //Delete User
  delete = async (e, memberId) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let deleteObj = {
      groupId: e._id,
      empId: memberId.empId,
    };
    await this.props.deleteUserFromGroup(deleteObj, accessToken); //Delete User from Group
    await this.props.getAllGroups(accessToken);
  };

  //Expanded Row
  expandedRowRender = (e, index) => {
    const userCol = [
      {
        title: "Name",
        dataIndex: "name",
        align: "left",
      },
      {
        title: "MobileNumber",
        dataIndex: "cellPhone",
        align: "left",
      },
      {
        title: "Email",
        dataIndex: "email",
        align: "left",
      },
      {
        title: "Emp Id",
        dataIndex: "empId",
        align: "left",
      },
      {
        title: "Action",
        key: "operation",
        dataIndex: "",
        render: (record) => (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Tooltip title="Remove User">
                <Popconfirm
                  placement="left"
                  title="Are you sure, you want to delete the User?"
                  onConfirm={() => this.delete(e, record)}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                >
                  <DeleteOutlined
                    style={{
                      color: "#880808",

                      fontSize: "18px",
                    }}
                    size="small"
                    shape="circle"
                  />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        ),
      },
    ];
    let userData = [];
    userData = e.members;

    return <Table columns={userCol} dataSource={userData} rowKey="_id" />;
  };

  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ loadingBroadcast: false });
    this.props.getAllGroups(this.props.history, accessToken);
  }

  onEditGroupName = async (id) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ loadingBroadcast: true });
    const groupId = this.state.groupId;
    const groupName = this.state.newgroupname;
    await axios.patch(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group?groupId=${groupId}&name=${groupName}`,
        {},
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then(async (res) => {
        console.log("Res====> ", res);
        message.success(`Group Name Updated Successfully`);
        let accessToken = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
        this.setState({ loadingBroadcast: false });
        this.setState({ editgroupvisible: false });
        this.props.getAllGroups(this.props.history, accessToken);
      })
      .catch((error) => {
        console.log("Failed to update group name due to ===> ", error);
      });
  };

  showEditGroupModal = (id) => {
    this.setState({
      editgroupvisible: true,
      groupId: id._id,
      groupName: id.name,
    });
  };

  handleEditGroupModalCancel = () => {
    this.setState({ editgroupvisible: false });
  };

  onDeleteGroup = async (id) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ loadingBroadcast: true });

    axios
      .delete(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group?groupId=${id}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(async (res) => {
        message.success(`Group Deleted Successfully`);
        let accessToken = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
        this.setState({ loadingBroadcast: false });

        this.props.getAllGroups(this.props.history, accessToken);
      })
      .catch((error) => {
        console.log("Failed to delete group due to ===> ", error);
      });
  };

  //Remove Upload File
  removeUploadedFile = () => {
    this.setState({
      setFile: null,
      fileList: [],
      previewVisible: false,
      fileName: "",
    });
  };

  //File Modal Cancel
  handleModalCancel = () => {
    this.setState({ visible: false, fileList: [], inputFile: null });
  };

  //Modal cancel
  handleCancel = async (file) => {
    this.setState({
      previewVisible: false,
    });
  };

  //Show Modal
  showModal = (record) => {
    this.setState({
      visible: true,
      members: record.members,
      groupId: record._id,
    });
  };

  //Show Feedback Modal
  showFeedbackModal = (value) => {
    this.setState({ groupName: value });
    this.setState({ feedbackValue: value });
    this.setState({ modalFeedback: true });
  };

  //Navigate to View Broadcast
  navigateToViewBroadcast = (event) => {
    this.setState({ groupObject: event, navigateToBroadcast: true });
  };

  closeFeedbackModal = () => {
    this.setState({ modalFeedback: false });
    this.setState({
      question: "",
      questionList: [],
      feedbackMessage: "",
      eventName: "",
      index: "",
      indexNumber: {},
    });
  };

  onSelectEvent = (value) => {
    var event = this.state.eventList.filter((event) => event._id === value);
    this.setState({ eventName: event[0].name });
  };

  onSave = async (index) => {
    const feedbackQue = this.state.question;
    this.setState((prevState) => ({
      questionList: [
        ...prevState.questionList,
        { question: "Q" + (index + 1), text: feedbackQue },
      ],
    }));

    let tempArray = this.state.indexNumber;
    tempArray[index] = true;
    this.setState({ indexNumber: tempArray });
  };

  onRemove(e) {
    const reducedArr = [...this.state.eventList];
    reducedArr.splice(e, 1);
    this.setState({ eventList: reducedArr });
    let tempArray = this.state.indexNumber;
    tempArray[e] = false;
    this.setState({ indexNumber: tempArray });
  }
  onQueChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onMessageChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getReciepients = () => {};

  feedbackSubmit = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ sendBtnLoading: true });
    const listItems = this.props.groups.filter(
      (group) => group.name === this.state.feedbackValue
    );
    let feedBackObj = {
      eventname: this.state.eventName,
      feedbackmessag: this.state.feedbackMessage,
      recipients: listItems[0].members,
      questions: this.state.questionList,
      groupname: this.state.groupName,
      feedbackReminderSwitch: this.state.feedBackSwitch,
    };
    const response = await axios.post(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/feedback`,
      feedBackObj,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    if (response.status === 200) {
      this.setState({ sendBtnLoading: false });
      message.success(`Feedback Submitted Successfully!!`);
    }
    console.log("Response", response);

    this.setState({
      question: "",
      questionList: [],
      feedbackMessage: "",
      eventName: "",
      index: "",
      indexNumber: {},
    });
    this.closeFeedbackModal();
  };

  render() {
    const { previewVisible, previewImage, previewTitle } = this.state;
    const groupCol = [
      {
        title: "Name",
        dataIndex: "name",
        align: "left",
      },
      {
        title: "Total Members",
        key: "operation",
        align: "center",
        render: (record) => {
          return (
            <>
              {record.members.length > 0 ? (
                <Tag color="green">{record.members.length}</Tag>
              ) : (
                <Tag color="volcano">{record.members.length}</Tag>
              )}
            </>
          );
        },
      },
      {
        title: "No. of Broadcasts",
        key: "operation",
        align: "center",
        render: (record) => {
          return (
            <>
              {record.broadcastMessages.length > 0 ? (
                <Tag color="green">{record.broadcastMessages.length}</Tag>
              ) : (
                <Tag color="volcano">{record.broadcastMessages.length}</Tag>
              )}
              <div>
                <Progress
                  percent={record.broadcastMessages.length}
                  strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                  showInfo={false}
                />
              </div>
            </>
          );
        },
      },

      {
        title: "Actions",
        key: "_id",
        align: "center",
        render: (record) => {
          return (
            <Row gutter={[16, 16]}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                {record.members.length > 0 ? (
                  <Tooltip title="Broadcast Message">
                    <Button type="link" onClick={() => this.showModal(record)}>
                      Broadcast
                    </Button>
                  </Tooltip>
                ) : (
                  <Button disabled type="link">
                    Broadcast
                  </Button>
                )}
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                {record.members.length > 0 ? (
                  <Tooltip title="Feedback">
                    <Button
                      type="link"
                      onClick={() => this.showFeedbackModal(record.name)}
                    >
                      Feedback
                    </Button>
                  </Tooltip>
                ) : (
                  <Button disabled type="link">
                    Feedback
                  </Button>
                )}
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Tooltip title="View Group">
                  <EyeTwoTone
                    twoToneColor="#5046e5"
                    style={{ fontSize: "20px" }}
                    onClick={(event) => this.navigateToViewBroadcast(record)}
                  />
                </Tooltip>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Tooltip title="Edit Group Name">
                  <EditTwoTone
                    twoToneColor="#5046e5"
                    style={{ fontSize: "20px" }}
                    onClick={(event) => this.showEditGroupModal(record)}
                  />
                </Tooltip>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Tooltip title="Delete Group">
                  <Popconfirm
                    placement="left"
                    title="Are you sure, you want to delete the Group?"
                    loading={this.state.loadingBroadcast}
                    onConfirm={() => this.onDeleteGroup(record._id)}
                    onCancel={() => this.cancelCallOnDelete()}
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: "red",
                        }}
                      />
                    }
                  >
                    <DeleteTwoTone
                      twoToneColor="#d91d0f"
                      style={{
                        fontSize: "18px",
                      }}
                      size="small"
                      shape="circle"
                    />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
          );
        },
      },
    ];

    const { groups } = this.props;

    return (
      <div className="container dashboard statistics-card">
        {this.state.navigateToBroadcast ? (
          <Navigate
            to="/groups/customgroupbroadcast"
            state={this.state.groupObject}
          />
        ) : (
          ""
        )}
        <Modal
          title="Feedback"
          style={{
            top: 20,
          }}
          open={this.state.modalFeedback}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          // onOk={() => this.showFeedbackModal(false)}
          onCancel={() => this.closeFeedbackModal()}
          destroyOnClose={true}
        >
          <Form name="add-Feedback" onFinish={this.feedbackSubmit}>
            <Form.Item
              name="selectEvent"
              rules={[
                {
                  required: true,
                  message: "Please Select Event!",
                },
              ]}
            >
              <Select
                // defaultValue="Choose Event"
                placeholder="Choose Event"
                style={{
                  width: " 100%",
                }}
                name="selectEvent"
                id="selectEvent"
                onChange={this.onSelectEvent}
              >
                {this.state.eventList.map((event) => {
                  return <Option value={event._id}>{event.name}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.List name="sights">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row>
                      <Space key={field.key} align="baseline">
                        <Col span={24} style={{ width: "320px" }}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }
                            rules={[
                              {
                                required: true,
                                message: "Please Add Question!",
                              },
                            ]}
                          >
                            {() => (
                              <Form.Item
                                {...field}
                                name={[field.name, "question"]}
                              >
                                <Input
                                  disabled={
                                    this.state.indexNumber[index] === true
                                      ? true
                                      : false
                                  }
                                  placeholder="Add Question"
                                  name="question"
                                  value={this.state.question}
                                  onChange={this.onQueChange}
                                />
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Col>
                        <Col>
                          <MinusCircleTwoTone
                            twoToneColor="red"
                            style={{ fontSize: "18px" }}
                            onClick={() => {
                              remove(field.name);
                              this.onRemove(index);
                            }}
                          />
                          <Button
                            style={{ border: "none" }}
                            disabled={
                              this.state.indexNumber[index] === true
                                ? true
                                : false
                            }
                          >
                            <SaveTwoTone
                              twoToneColor="#5046e5"
                              style={{ fontSize: "18px" }}
                              onClick={() => this.onSave(index)}
                            />
                          </Button>
                        </Col>

                        {this.state.indexNumber[index] === true && (
                          <Text type="success">Saved!!</Text>
                        )}
                      </Space>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Question
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item
              name="feedbackMessage"
              rules={[
                {
                  required: true,
                  message: "Please add feedback message!",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Message..."
                name="feedbackMessage"
                value={this.state.feedbackMessage}
                onChange={this.onMessageChange}
              />
            </Form.Item>

            <Form.Item className="float-end" style={{ width: "100%" }}>
              <Tooltip title="Reminder Feedback">
                Send Reminder
                <Switch
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                  onChange={(value) => {
                    this.setState({ feedBackSwitch: value });
                  }}
                />
              </Tooltip>

              <Button
                type="primary"
                htmlType="submit"
                className="ok-modal"
                style={{ float: "right" }}
                loading={this.state.sendBtnLoading}
              >
                Save
              </Button>
              <Button
                className="close-modal me-3"
                onClick={this.closeFeedbackModal}
                style={{ float: "right" }}
              >
                Close
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Broadcast"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
        >
          <Form
            name="add-users"
            className="add-users"
            onFinish={this.onSubmit}
            initialValues={{ remember: true }}
          >
            <div className="card">
              <div className="card-body p-5">
                <div>
                  <div
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      display: "flex",
                      margin: "0 0 10px 0",
                      justifyContent: "center",
                    }}
                  >
                    <h4 style={{ fontWeight: "bold", textAlign: "center" }}>
                      Broadcast Message
                    </h4>
                  </div>

                  <>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item name="upload" valuePropName="fileList">
                        {this.state.setFile ? (
                          <Row gutter={[8, 8]}>
                            {(this.state.fileType === "image/png" ||
                              this.state.fileType === "image/jpeg") && (
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="container-fluid text-center">
                                  <img
                                    alt="Not available"
                                    width={125}
                                    height={125}
                                    src={this.state.setFile}
                                  />
                                </div>
                              </Col>
                            )}
                            {this.state.fileType === "video/mp4" && (
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="container-fluid text-center">
                                  <video width="125" height="125" controls>
                                    <source
                                      src={this.state.setFile}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              </Col>
                            )}
                            {this.state.fileType === "application/pdf" && (
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="container-fluid text-center">
                                  <img
                                    alt="Not available"
                                    width={125}
                                    height={125}
                                    src={pdfImg}
                                  />
                                </div>
                              </Col>
                            )}
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Row className="ms-2">
                                <div style={{ margin: "0 10px 0 auto" }}>
                                  {this.state.fileType !== "video/mp4" && (
                                    <PreviewUploadedImage
                                      file={this.state.setFile}
                                      fileType={this.state.fileType}
                                    />
                                  )}
                                </div>
                                <div style={{ margin: "0 auto 0 0" }}>
                                  <button
                                    onClick={this.removeUploadedFile}
                                    style={{ width: "50px" }}
                                    type="button"
                                    class="btn btn-outline-danger btn-sm"
                                  >
                                    <DeleteOutlined />
                                  </button>
                                </div>
                              </Row>
                            </Col>
                            <div className="m-auto mt-2 text-center">
                              <label>
                                <h4>{this.state.fileName}</h4>
                              </label>
                            </div>
                          </Row>
                        ) : (
                          <div className="text-center">
                            <label
                              for="inputFile"
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              <div class="mb-3 drag-area ">
                                <h6>Upload</h6> <PlusOutlined />
                                <input
                                  class="form-control"
                                  type="file"
                                  id="inputFile"
                                  name="inputFile"
                                  value={this.state.inputFile}
                                  onChange={this.handleFileChange}
                                  hidden
                                  accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4"
                                />
                              </div>
                            </label>
                          </div>
                        )}

                        <Modal
                          visible={previewVisible}
                          footer={null}
                          onCancel={this.handleCancel}
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                        <Modal
                          visible={previewVisible}
                          title={previewTitle}
                          footer={null}
                          onCancel={this.handleCancel}
                          destroyOnClose="true"
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item name="message">
                        <TextArea
                          placeholder="Type Broadcast message"
                          name="message"
                          value={this.state.message}
                          onChange={this.onChange}
                          rows={4}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item>
                        {this.state.loadingBroadcast && (
                          <div
                            className=""
                            style={{ margin: "5px 50px 20px 200px" }}
                          >
                            <Spin />
                          </div>
                        )}
                        {!this.state.loadingBroadcast && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="ok-modal"
                            disabled={
                              this.state.fileList.length ||
                              this.state.message !== ""
                                ? false
                                : true
                            }
                            block
                          >
                            Send Message
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </>
                </div>
              </div>
            </div>
            <hr></hr>
          </Form>
        </Modal>

        <Modal
          visible={this.state.editgroupvisible}
          onOk={this.handleOk}
          onCancel={this.handleEditGroupModalCancel}
          title="Edit Group Name"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
        >
          <Form
            name="add-Group"
            className="add-Group"
            // onFinish={this.onEditGroupName}
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Enter New Group Name!",
                },
              ]}
            >
              <Input
                autoFocus
                placeholder={this.state.groupName}
                name="name"
                value={this.state.newgroupname}
                onChange={(e) =>
                  this.setState({ newgroupname: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item className="float-end">
              <Button
                className="close-modal me-3"
                onClick={this.handleEditGroupModalCancel}
              >
                Close
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="ok-modal"
                loading={this.state.loadingBroadcast}
                onClick={this.onEditGroupName}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <div className="row " data-inline="true">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={20} lg={18} xl={16}>
              <div style={{ margin: "-9px 0px 0px 4px" }}>
                <h2 className="page-title">Group Management</h2>
                <BreadCrumbs location={this.props.location} />
              </div>
            </Col>

            <Col xs={7} sm={7} md={4} lg={3} xl={4}>
              <AddMembers />
            </Col>
            <Col xs={7} sm={7} md={4} lg={3} xl={3 }>
              <AddGroup />
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <div className="card">
            <div className="card-body p-5">
              {groups.length ? (
                <Table
                  expandedRowRender={(e, index) =>
                    this.expandedRowRender(e, index)
                  }
                  loading={this.state.loading}
                  columns={groupCol}
                  dataSource={groups}
                  rowKey="_id"
                  align="left"
                />
              ) : (
                <div className="spinLoader">
                  <Spin />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => (
  console.log("console state users",state.users),
  {
  errors: state.errors,
  groups: state.users.groups,
  sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
});
export default connect(mapStateToProps, {
  broadCastMessage,
  getAllGroups,
  deleteUserFromGroup,
})(Groups);
