import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/charts";
import { Card, Spin, Select, Tooltip, Button } from "antd";
import axios from "axios";
import { CSVLink } from "react-csv";
import { CRow } from "@coreui/react";
import { DownloadOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";

const { Option } = Select;
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FFC0CB"];

const PieChartComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      try {
        const response = await axios.get(
          "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/feedbackreport",
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        const newResp = JSON.parse(response.data.body);
        const feedbackData = newResp.data?.map((eventData) => {
          const feedback = eventData?.feedback?.map((questionData) => ({
            name: questionData.Question,
            value: parseFloat(questionData.answer) || 0, // Convert answer to float or default to 0
          }));
          return { name: eventData.eventname, feedback };
        });
        setData(feedbackData);
        setLoading(false);
        setSelectedEvent(feedbackData[0].name); // Set the default selected event to the first event in the array
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleEventChange = (value) => {
    setSelectedEvent(value);
  };

  const selectedEventData = data?.find(
    (eventData) => eventData.name === selectedEvent
  );
  const config = {
    data: selectedEventData?.feedback || [],
    angleField: "value",
    colorField: "name",
    radius: 0.8,
    label: {
      type: "inner",
      offset: "-30%",
      style: {
        textAlign: "center",
      },
      font: {
        size: 20,
      },
    },
    legend: true,
    interactions: [{ type: "element-active" }],
    color: COLORS,
  };

  const headers = [
    { label: "Event", key: "event" },
    { label: "Question", key: "question" },
    { label: "Response", key: "response" },
  ];

  let reportData = [];
  const feedbackdata = selectedEventData;
  const eventfData = { event: feedbackdata?.name };
  const feedbackRows = feedbackdata?.feedback?.map((item) => ({
    question: item.name,
    response: item.value,
  }));
  if (feedbackRows != null) {
    reportData = [eventfData, ...feedbackRows];
  }

  return (
    <Card>
      <div className="d-flex justify-content-center">
        <h5 className="text-secondary mb-3">Cumulative Feedback Report</h5>
      </div>

        <CSVLink
          data={reportData}
          headers={headers}
          className="float-end btn-dwn"
          filename={"feedback-report.csv"}
        >
          <Tooltip title="Export Report to csv">
            <div className="float-end dwnld-btn">
              <Button size="large" type="text">
                <DownloadOutlined />
              </Button>
            </div>
          </Tooltip>
        </CSVLink>
      
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div className="card-body">
          <div className="select_event">
            <span className="count">
              <div className="count-cards-select">
                <Select
                  value={selectedEvent}
                  onChange={handleEventChange}
                  style={{ width: 200 }}
                >
                  {data?.map((eventData) => (
                    <Option key={eventData.name} value={eventData.name}>
                      {eventData.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </span>
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      ) : (
        <Pie {...config} />
      )}
    </Card>
  );
};

export default PieChartComponent;
