import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Row,
    Select,
    Space,
    Table,
    Tabs,
    Tooltip,
    Modal
} from "antd";
import { Auth } from "aws-amplify";
import axios from "axios";
import { saveAs } from "file-saver";
import Pusher from "pusher-js";
import { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { getAllQueriesByBroadcastId } from "../../../actions/eventActions";
import {
    Routes,
    Route,
    Navigate,
    useNavigate,
    useLocation,
  } from "react-router-dom";
import {
    broadCastMessage,
    getAllBroadCastStatusesbyBroadcastId,
    getAllGroups,
} from "../../../actions/userAction";
import BreadCrumbs from "../../BreadCrumbs";

class ViewBroadcastMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingTable: true,
      initialData: null,
      tableData: null,
      selectOption: "Status",
      visible: false,
      navigateToMessage: false
    };
  }

  // Method to fetch data from DB
  fetchData = async () => {
    this.setState({ loadingTable: true });
    await this.props.getAllBroadCastStatusesbyBroadcastId(
      this.props.location.state._id
    );
    console.log("this.props console", this.props);
    this.setState({
      initialData: this.props.broadcastDetail?.allrecipients || [],
      tableData: this.props.broadcastDetail?.allrecipients || [],
      filterData: this.props.broadcastDetail?.allrecipients || [],
      loadingTable: false,
    });
  };

  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    // await  this.props.getAllGroups( accessToken)
    await this.fetchData();

    // Initialize Pusher
    this.pusher = new Pusher("52f42b6764ad744f84ed", {
      cluster: "ap2",
      useTLS: true,
    });
    this.channel = this.pusher.subscribe("messages");
    this.channel.bind("status-update", this.handleStatusUpdate);
    this.channel.bind("reaction-update", this.handleReactionUpdate);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    this.onSubmit()
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  // Updated Event Handlers for Pusher Events
  handleStatusUpdate = async (data) => {
    console.log("handleStatusUpdate function is called");
    const { messageId, status } = data;
    this.setState((prevState) => {
      const updatedData = prevState.initialData.map((message) => {
        if (
          message.messageId &&
          message.messageId.trim() === messageId.trim()
        ) {
          return { ...message, status: status || message.status };
        }
        return message;
      });

      return { initialData: updatedData, tableData: updatedData };
    });
    // await this.fetchData();
  };

  handleReactionUpdate = async (data) => {
    console.log("handleReactionUpdate function is called");
    const { messageId, reaction } = data;
    this.setState((prevState) => {
      const updatedData = prevState.initialData.map((message) => {
        if (
          message.messageId &&
          message.messageId.trim() === messageId.trim()
        ) {
          return { ...message, reaction: reaction || message.reaction }; // Merge reaction without affecting status
        }
        return message;
      });
      return { initialData: updatedData, tableData: updatedData };
    });
    // await this.fetchData();
  };

  getGroupByBroadcastid = async (broadcastId) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    console.log("broadcastID console", broadcastId);
    await this.props.getAllGroups(accessToken);
    console.log("console this.props", this.props?.groups);
    const result = this.props?.groups.find((group) =>
      group.broadcastMessages.includes(broadcastId)
    );
    console.log("result console", result);
    if (result) {
      console.log("Group ID:", result._id);
      console.log("Group Name:", result.name);
      return result._id;
    } else {
      console.log("Broadcast ID not found in any group.");
    }
  };

  formatPhoneNumber = (number) => {
    // Convert the number to a string if it's not already
    const str = number.toString();

    // Extract the country code and local number
    const countryCode = str.slice(0, 2);
    const localNumber = str.slice(2);

    // // Format the local number into groups
    // const formattedLocalNumber = localNumber.replace(/(\d{5})(\d{5})$/, '$1 $2');

    // Combine everything into the desired format
    return `(+${countryCode}) ${localNumber}`.trim();
  };

getMimeType = (fileName) => {
    const mimeTypes = {
        '.mp4': 'video/mp4',
        '.jpeg': 'image/jpeg',
        '.jpg': 'image/jpeg',
        '.png': 'image/png',
        '.pdf': 'application/pdf',
    };
 
    const extension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
 
    return mimeTypes[extension] || 'unknown/unknown';
}
  
  onSubmit = async () => {
    this.setState({ loadingTable: true });
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let status;
    console.log("this.props.broadcastDetails", this.props.broadcastDetail);
    let groupId = await this.getGroupByBroadcastid(
      this.props.broadcastDetail._id
    );
    console.log("group id for this broadcast", groupId);
    const allrecipients = this.state.tableData;

    status = allrecipients.filter((data) => data.status === "failed");
    // console.log("failed status members", status);
    const group = this.props.groups.find((group) => group._id === groupId);
    let matchedMembers;
    if (group) {
      matchedMembers = group.members.filter((member) =>
        status.some(
          (s) => s.number.trim() === member.cellPhone.replace(/[^\d]/g, "")
        )
      );
    //   console.log("Matched Members:", matchedMembers);
    } else {
      console.log("Group not found.");
      console.log("failed recipients console", matchedMembers);
    }

    let requestObj;
    let response;
    if(this.props.broadcastDetail.media==""){
        let pathName = null;
        const requestObj = {
        broadCastId: this.props?.broadcastDetail?._id,
        groupId: groupId,
        broadcastMessage: this.props?.broadcastDetail?.message,
        recipients: matchedMembers,
        pathName: pathName,
        resend: "true",
        type: "Normal",
        };

        console.log("Inside else media empty",requestObj);
        response = await this.props.broadCastMessage(
        requestObj,
        this.props.history,
        accessToken
        ); //Broadcasting Message
    }
    else{
        console.log("get mimetype for this file",this.getMimeType(this.props?.broadcastDetail?.fileName))
        console.log("get matchedMembers",matchedMembers)
        let mimetype = this.getMimeType(this.props?.broadcastDetail?.fileName)
        requestObj = {
            broadcastMessage: this.props?.broadcastDetail?.message,
            recipients: matchedMembers,
            mimetype: mimetype,
            pathName: this.props?.broadcastDetail?.media,
            broadCastId: this.props?.broadcastDetail?._id,
            resend:"true",
            type: "Normal",
          };
          response = await this.props.broadCastMessage(
            requestObj,
            this.props.history,
            accessToken
          ); //Broadcasting Message
    }
    console.log("response of broadcast message function", response);
    if(response.status==200){
        this.setState({ selectOption: "Status" });
        this.fetchData(); // Re-fetch the latest data from DB
        console.log("response of broadcast message function", response);
        this.setState({ loadingTable: false });
        this.setState({ navigateToMessage: true});
    }
  };

  // Refresh button to reload data
  refreshData = () => {
    this.setState({ selectOption: "Status" });
    this.fetchData(); // Re-fetch the latest data from DB
  };

  // Downloading Image
  downloadImage = async (key) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl?key=${key}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    saveAs(response.data.url);
  };

  // Handle Status Filter Change
  messageStatus = (value) => {
    this.setState({ selectOption: value });
    let status;
    if (
      value === "sent" ||
      value === "failed" ||
      value === "read" ||
      value === "delivered"
    ) {
      const allrecipients = this.state.tableData;
      status = allrecipients.filter((data) => data.status === value);
    } else {
      status = this.state.tableData;
    }
    this.setState({ initialData: status });
  };

  render() {

    const headers = [
      { label: "Message", key: "message" },
      { label: "Media", key: "media" },
      { label: "Number", key: "number" },
      { label: "Recipient Name", key: "recipientName" },
      { label: "Status", key: "status" },
      { label: "Reaction", key: "reaction" },
    ];
    let resp;
    if (this.state.selectOption != "Status") {
      resp = this.props.broadcastDetail?.allrecipients?.filter(
        (data) => data.status === this.state.selectOption
      );
    } else {
      resp = this.props.broadcastDetail?.allrecipients;
    }

    const data =
      resp?.map((element) => {
        element.message = this.props.broadcastDetail.message;
        element.media = this.props.broadcastDetail.media;
        element.number = element.number + " ";
        return element;
      }) || [];

    const items = [
      { key: "1", value: "Status", label: "Status" },
      { key: "2", value: "sent", label: "Sent" },
      { key: "3", value: "failed", label: "Failed" },
      { key: "4", value: "delivered", label: "Delivered" },
      { key: "5", value: "read", label: "Read" },
    ];

    const messageStatisticsCols = [
      { title: "Number", dataIndex: "number", align: "left", width: "200px" },
      { title: "Name", dataIndex: "recipientName", align: "center" },
      {
        title: "Reaction",
        dataIndex: "reaction",
        align: "center",
        render: (reaction) =>
          reaction ? (
            <p className="text-success my-para">{reaction}</p>
          ) : (
            <p> -- </p>
          ),
      },
      {
        title: "Resend",
        dataIndex: "resend",
        align: "center",
        render: (resend) =>
          resend ? (
            <p className="text-success my-para">Yes</p>
          ) : (
            <p>No</p>
          ),
      },
      {
        title: (
          <Space size="middle">
            <Select
              onChange={this.messageStatus}
              style={{ width: "110px" }}
              value={this.state.selectOption}
              options={items}
            />
          </Space>
        ),
        dataIndex: "status",
        width: "200px",
        align: "center",
        render: (text) =>
          text === "read" ||
          text === "sent" ||
          text === "delivered" ||
          text === "failed" ? (
            <p className="text-success my-para">{text}</p>
          ) : (
            <p className="text-danger">
              Number is invalid or not registered with WhatsApp
            </p>
          ),
      },
    ];

    return (
    <>
        
        {/* {this.state.navigateToMessage ? (
        <Navigate
          to="/groups/customgroupbroadcast"
          state={this.state.loadingTable}
        />
      ) : (
        ""
      )} */}
      <div className="container dashboard statistics-card">
        <Modal
          title="Resend"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        //   loading="true"
        >
          <p>Are you sure you want to resend the failed members?</p>
         
        </Modal>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <div className="page-title">Message</div>
            <BreadCrumbs location={this.props.location} />
          </Col>
          <Col span={12}>
            <Space className="float-end">
              {/* <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="ok-modal-broadcast mx-3"
                                    disabled={!(this.state.selectOption=="failed"&&this.state.initialData!="")}
                                    // onClick={() => this.onSubmit()}
                                    //   block
                                    >
                                    Resend Failed
                            </Button> */}

              {/* <Popconfirm
                            title="Are you sure you want to resend the failed messages?"
                            onConfirm={() => this.onSubmit()} // Call your onSubmit method
                            onCancel={() => message.info("Resend canceled.")} // Optional cancel handler
                            okText="Yes"
                            cancelText="No"
                            >
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="ok-modal-broadcast mx-3"
                                disabled={!(this.state.selectOption === "failed" && this.state.initialData.length > 0)}
                                block
                            >
                                Resend Failed
                            </Button> */}
              {/* </Popconfirm> */}

              {this.state.selectOption === "failed" &&
              this.state.initialData.length > 0 ? (
            
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal-broadcast mx-3"
                    onClick={this.showModal}
                    // block
                  >
                    Resend BroadCast To Failed
                  </Button>
                // </Popconfirm>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal-broadcast mx-3"
                  disabled
                  block
                >
                  Resend Failed
                </Button>
              )}
              <CSVLink
                data={data}
                headers={headers}
                className="btn-dwn"
                filename={"message-statistics-report.csv"}
              >
                <Tooltip title="Export to CSV">
                  <Button
                    size="large"
                    type="text"
                    disabled={this.state.initialData == ""}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              </CSVLink>
              <Tooltip title="Refresh Data">
                <Button size="large" type="text" onClick={this.refreshData}>
                  <ReloadOutlined />
                </Button>
              </Tooltip>
            </Space>
          </Col>
        </Row>
        <div className="mt-4">
          <div className="card">
            <div className="card-body p-5">
              <Tabs
                defaultActiveKey="1"
                onChange={this.onChange}
                items={[
                  {
                    label: `Message Statistics`,
                    key: "2",
                    children: (
                      <Table
                        columns={messageStatisticsCols}
                        dataSource={this.state.initialData}
                        loading={this.state.loadingTable}
                        // loading="true"
                        rowKey="_id"
                        align="left"
                      />
                    ),
                  },
                  {
                    label: `Attachments`,
                    key: "3",
                    children: this.props.location.state.media ? (
                      <Row className="title-row">
                        <Col>{this.props.location.state.fileName}</Col>
                        <Col xs={4} sm={4} md={4} lg={1} xl={1}></Col>
                        <Col>
                          <button
                            type="button"
                            className="rounded btn-success"
                            onClick={() =>
                              this.downloadImage(
                                this.props.location.state.media
                              )
                            }
                          >
                            <DownloadOutlined />
                          </button>
                        </Col>
                      </Row>
                    ) : (
                      <div>No Attachments</div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  groups: state.users.groups,
  broadcastDetail: state.users.broadcastDetail,
  sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
  queries: state.events.queries,
});

export default connect(mapStateToProps, {
  broadCastMessage,
  getAllQueriesByBroadcastId,
  getAllBroadCastStatusesbyBroadcastId,
  getAllGroups,
})(ViewBroadcastMessage);
