import { Pie } from "@ant-design/charts";
import { DownloadOutlined } from "@ant-design/icons";
import { CRow } from "@coreui/react";
import { Button, Col, Row, Select, Tooltip ,Spin,Tag} from "antd";
import { Auth } from "aws-amplify";
import { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { getAllEvents } from "../../../actions/eventActions";
import axios from "axios";
const { Option } = Select;
var reportData = [];
let eventName;
class BroadcastPieChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiData: null,
      selectedBroadcast: null,
      sent: 0,
      broadcastType: null,
      broadcastName: null,
      campaignSelected: true,
      failed: 0,
      broadcastsInCampaing: null,
      delivered: 0,
      totalRecipients: 0,
      message: "",
      events:[],
      loading:true,
      reactions:null,
    };
  }

getAllBroadCastStatusesbyBroadcastId = (broadcastId) => async () => {
    // let broadcastId ="671f645f11ad84ee7211d079"
    console.log("console broadcastid id as parameter",broadcastId)
    try {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      let response = await axios.get(
        // `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group`,
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/broadcastsList/broadcastDetail?id=${broadcastId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );

      this.setState({
        broadcastsInCampaing: response.data.broadcastMessages,
      });
    } catch (error) {
      console.log("#error", error);
    }
  };

  async componentDidMount() {
    console.log("this.state.broadcastType",this.state.broadcastType)
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

      const response = await axios.get(
        "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/groupsAll",
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      console.log("console log apiData",response.data.body.data)
 // Set the fetched events data into state
this.setState({
  events: response.data.body.data, // Update events in state
  loading:false
});
  }
  //Select Event
  changeBroadcastCampaige = async (value) => {
    console.log("value console log",value)
    if (value !== undefined) {
      var event = this.state.events.filter((event) => event._id === value);
      eventName = event[0].name;
      try {
        let groupId=value;
        this.setState({
          loading:true,
          reaction:null
        });
        let accessToken = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
        let response = await axios.get(
          `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/broadcastsList?id=${groupId}`,
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        // console.log("Resp broadcasts list by id", response);
        console.log("Resp broadcasts list by id", response.data.data);
        this.setState({
          broadcastType: value,
          broadcastName: null,
          totalRecipients: 0,
          sent: 0,
          failed: 0,
          read:0,
          delivered:0,
          // broadcastsInCampaing: event[0].broadcastMessages,
          broadcastsInCampaing: response.data.data,
          campaignSelected: false,
          loading:false
        });
        // console.log("broadcastsInCampaing console",this.state.broadcastsInCampaing)
      } catch (error) {
        console.log("#error", error);
      }
      // console.log("console log response by id",response)
     
    } else {
      this.setState({
        broadcastType: null,
        totalRecipients: 0,
        sent: 0,
        failed: 0,
        read:0,
        delivered:0,
        broadcastName: null,
        selectedBroadcast: null,
        broadcastsInCampaing: null,
        campaignSelected: true,
        loading:false,
        reaction:null
      });
    }
  };

  //Select Broadcast
  broadcastChange = async (value) => {
    this.setState({
      loading:true
    });
    console.log("change broadcast")
    let broadcastId = value
    console.log("broadcastId console",broadcastId)
    try {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      let response = await axios.get(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/group/broadcastsList/broadcastDetail?id=${broadcastId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      // console.log("Resp broadcasts list by id", response);
      

      if (value === undefined) {
        this.setState({
          broadcastName: null,
          totalRecipients: 0,
          sent: 0,
          read:0,
          delivered:0,
          failed: 0,
          loading:false,
          reaction:0
        });
      } else {
        this.setState({
          broadcastName: value,
          loading:false
        });
        var broadcast =  response.data.data;
        let totalSent = 0;
        let totalFailed = 0;
        let totalRead = 0;
        let totalDelivered = 0;
        let  totalReactions =0;
        // console.log("var broadcast =  response.data.data", broadcast);
        if (broadcast.allrecipients.length) {
          broadcast.allrecipients.map((recipient) => {
            if(recipient.reaction!==""){
              totalReactions =totalReactions+1
            }
            if (recipient.status === "sent") {
              totalSent = totalSent + 1;
            } else if(recipient.status === "failed"){
              totalFailed = totalFailed + 1;
            }
            else if(recipient.status === "read"){
              totalRead = totalRead + 1;
            }
            else if(recipient.status === "delivered"){
              totalDelivered= totalDelivered + 1;
            }
          });
          reportData = [
            {
              event: eventName,
              broadcast: broadcast.message,
              totalSentMessages: totalSent,
              totalFailedMessages: totalFailed,
              totalReadMessages: totalRead,
              totalDeliveredMessages: totalDelivered,
            },
          ];
          this.setState({
            totalRecipients: broadcast.allrecipients.length,
            sent: totalSent,
            failed: totalFailed,
            read:totalRead,
            delivered:totalDelivered,
            reaction:totalReactions
          });
        } else {
          this.setState({ totalRecipients: 0, sent: 0, failed: 0,read: 0,delivered: 0});
        }
      }
      // console.log("broadcastsInCampaing console",this.state.broadcastsInCampaing)
    } catch (error) {
      console.log("#error", error);
    }
  };

  render() {
    const data = [
      {
        type: "Message Sent",
        value: this.state.sent,
      },
      {
        type: "Message Read",
        value: this.state.read,
      },
      {
        type: "Message Delivered",
        value: this.state.delivered,
      },
      {
        type: "Message Failed",
        value: this.state.failed,
      },
    ];
    const headers = [
      { label: "Group", key: "event" },
      { label: "Broadcast", key: "broadcast" },
      { label: "totalSentMessages", key: "totalSentMessages" },
      { label: "totalFailedMessages", key: "totalFailedMessages" },
      { label: "totalReadMessages", key: "totalReadMessages" },
      { label: "totalDeliveredMessages", key: "totalDeliveredMessages" },
    ];
    const config = {
      appendPadding: 10,
      data,
      angleField: "value",
      colorField: "type",
      radius: 0.6,
      color: ({ type }) => {
        if (type === "Message Sent") {
          return "#5678e8";
        }
        else if(type === "Message Read"){
          return "#0088FE"
        }
        else if(type === "Message Failed"){
          return "#00C49F"
        }else if(type === "Message Delivered"){
          return "#FFBB28"
        }
        return "#e36056";
      },
      label: {
        type: "inner",
        offset: "-30%",
        style: {
          fontSize: 0,
          textAlign: "center",
        },
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
    return (
      <div>
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <h5 className="text-secondary  mb-3">Group Broadcasts Detail</h5>
          </div>
         
            <CSVLink
              data={reportData}
              headers={headers}
              className="float-end btn-dwn"
              filename={"group-report.csv"}
            >
              {this.state.broadcastName === null ? (
                <div className="float-end dwnld-btn">
                  <Button
                    size="large"
                    type="text"
                    disabled={this.state.broadcastName === null}
                  >
                    <DownloadOutlined />
                  </Button>
                </div>
              ) : (
                <Tooltip title="Export Report to csv">
                  <div className="float-end dwnld-btn">
                    <Button size="large" type="text">
                      <DownloadOutlined />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </CSVLink>
         
          <Row gutter={[24, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <div
                  style={{
                    border: "none",
                    height: "90px",
                  }}
                >
                  <div className="card-body">
                    <div className="select_event">
                      <span className="count ">
                        <div className="count-cards-select">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                ?.toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            name="broadcastType"
                            placeholder="Select Group"
                            id="broadcastType"
                            defaultValue={this.state?.events[0]?._id}
                            onChange={this.changeBroadcastCampaige}
                            value={this.state.broadcastType}
                          >
                            {this.state.events.map((item) => {
                              return (
                                <Option value={item._id}>{item.name}</Option>
                              );
                            })}
                          </Select>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  border: "none",
                  height: "90px",
                }}
              >
                <div className="card-body">
                  <div className="select_event">
                    <span className="count">
                      <div className="count-cards-select">
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          allowClear
                          disabled={this.state.campaignSelected}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              ?.toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          name="broadcastName"
                          placeholder="Select Broadcast"
                          id="broadcastName"
                          value={this.state.broadcastName}
                          onChange={this.broadcastChange}
                        >
                          {this.state.broadcastsInCampaing?.map((item) => {
                            return (
                              <Option value={item._id}>{item.message}</Option>
                            );
                          })}
                        </Select>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>{" "}
          <Row gutter={[24, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="card-body" style={{ margin: "-13px 0 0 0",textAlign:"center" }}>
                  <div className="details">
                    <span className="count ms-3">
                      <span className="name-cloud ">
                        Total number of Broadcasts for this group
                      </span>
                    </span>
                    <div className="count-cards-select">
                        <Tag style={{ fontSize: "initial" ,marginLeft:"10px"}} color="green">
                          {this.state?.broadcastsInCampaing?.length}
                        </Tag>
                      </div>
                  </div>
                </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="card-body" style={{ margin: "-13px 0 0 0" ,textAlign:"center" }}>
                  <div className="details">
                    <span className="count ms-3">
                      <span className="name-cloud ">
                        Total number of Reactions for this Broadcast
                      </span>
                    </span>
                    <div className="count-cards-select">
                        <Tag style={{ fontSize: "initial" ,marginLeft:"10px"}} color="green">
                          {/* {this.state.totalBroadcastInCampaing} */}
                          {this.state.reaction}
                        </Tag>
                      </div>
                  </div>
                </div>
            </Col>
          </Row>
          <div style={{ margin: "-85px 0 0 0" ,paddingRight:"350px",paddingLeft:"200px"}}>
            {/* <Pie {...config} /> */}
            {this.state.loading ? (
        <div style={{ display: "flex", justifyContent: "center" ,marginTop:"110px",marginLeft:"80px"}}>
          <Spin />
        </div>
      ) : (
        <Pie {...config} />
      )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  dashboardData: state.users.dashboardData,
  events: state.events.events,
});
export default connect(mapStateToProps, {
  getAllEvents,
})(BroadcastPieChart);
