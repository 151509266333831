import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/charts";
import { Button, Card, Select, Spin, Tooltip } from "antd";
import { CRow } from "@coreui/react";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";

const FeedbackIndividualReportPieChart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedName, setSelectedName] = useState("");

  const headers = [
    { label: "Event", key: "event" },
    { label: "Not Responded", key: "notresponded" },
    { label: "Responded", key: "responded" },
  ];

  useEffect(() => {
    // Fetch data from the API endpoint

    const fetchData = async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      fetch(
        "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/individualfeedbackresponse",
        { headers: { Authorization: "Bearer " + accessToken } }
      )
        .then((response) => response.json())
        .then((data) => {
          const newData = JSON.parse(data.body);
          // Convert data object to array for Pie chart
          const dataArray = Object.entries(newData).map(
            ([name, { total, responded }]) => ({
              name,
              total,
              responded,
            })
          );
          setData(dataArray);
          setLoading(false);
          setSelectedName(dataArray[0].name);
        })
        .catch((error) => console.error(error));
    };
    fetchData();
  }, []);

  const chartData = data.find((item) => item.name === selectedName);
  const { name, total, responded } = chartData ?? {};
  const notrespond = total < responded || total === 0 ? 0 : total - responded;

  const reportData = [
    {
      event: name,
      notresponded: notrespond,
      responded: responded,
    },
  ];

  const config = {
    appendPadding: 10,
    data: chartData
      ? [
          {
            type: "Not Responded",
            value:
              chartData.total < chartData.responded || chartData.total === 0
                ? 0
                : chartData.total - chartData.responded,
          },
          { type: "Responded", value: chartData.responded },
        ]
      : [],
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
      content: "{name}",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };

  const handleEventChange = (value) => {
    setSelectedName(value);
  };

  return (
    <Card>
      <div className="d-flex justify-content-center">
        <h5 className="text-secondary mb-3">Individual Feedback Report</h5>
      </div>
      
        <CSVLink
          data={reportData}
          headers={headers}
          className="float-end btn-dwn"
          filename={"individual-feedback-report.csv"}
        >
          <Tooltip title="Export Report to csv">
            <div className="float-end dwnld-btn">
              <Button size="large" type="text">
                <DownloadOutlined />
              </Button>
            </div>
          </Tooltip>
        </CSVLink>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div className="card-body">
          <div className="select_event">
            <span className="count">
              <div className="count-cards-select">
                <Select
                  value={selectedName}
                  onChange={handleEventChange}
                  style={{ width: 200 }}
                >
                  {data.map((item) => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
            </span>
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      ) : (
        <Pie {...config} />
      )}
    </Card>
  );
};
export default FeedbackIndividualReportPieChart;
